.nft-container {
    border: 1px rgb(84 77 68) solid;
    width: 150px;
    height: 150px;
    background-color: #24292d;
}

.missions-bar {
    display: inline-flex;
    justify-content: center;
    margin-top: 5px;
}

.mission-cell {
    border: 2px #36312b solid;
    background-color: rgb(84 77 68);
    padding: 5px 10px;
    transform: skewX(-20deg);
    -ms-transform: skewX(-20deg); /* IE 9 */
    -webkit-transform: skewX(-20deg); /* Safari */
    
}
.mission-list {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
}

.mission-stats {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
}

.selected-missions {
    color: white;
    text-align: center;
}

.nft-container img{
    object-fit: contain;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.selected {
    border: 1px solid green;
}

.nft-block {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.start-mission{
    text-align: center;
}

.mission-list .page-item:first-child .page-link {
    border-radius: .75rem;
  }
  
.mission-list .page-item:last-child .page-link {
    border-radius: .75rem;
  }

  .available {
    background-color: #ad8819;
    cursor: pointer;
  }

.missions-col{
    overflow-x: hidden;
    overflow-y: auto;
}

.missions-col::-webkit-scrollbar{
    width: 10px;
    
}

.missions-row{
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 1.5rem;
}

/* Track */
.missions-col::-webkit-scrollbar-track {
    background: #b7b7b7;
    border-radius: 5px;
  }
  
  /* Handle */
.missions-col::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }
  
  /* Handle on hover */
.missions-col::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 5px;
  }

  .img-container {
    overflow: hidden;
  }
  
  .sliding-background {
    background: url("../../public/image/mission/SpringShort1_2.png") no-repeat;
    height: 250px;
    /*background-size: contain;
    width: 5769px;
    animation: slide 30s linear infinite*/
    background-size: cover;
  }

  @keyframes slide {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-1923px, 0, 0); /* The image width */
    }
  }

.machine-section {
    background: url("../../public/image/machine/light-machine-3.png") no-repeat;
    height: 180px;
    /* width: 300px; */
    max-width: 400px;
    max-height: 180px;
    background-size: 100% 100%;
    position: relative;
    display: flex;
}

.bulb-north {

    background: url("../../public/image/machine/bulb-north.png") no-repeat;
    max-width: 50px;
    max-height: 60px;
    min-width: 25px;
    min-height: 25px;
    width: 100%;
    height: 100%;
    background-size: cover;
    /* background-size: 84px 54px; */
    /* background-clip: padding-box; */
    background-position: center;
}

.bulb-off {

  background: url("../../public/image/machine/bulb-off.png") no-repeat;
  max-width: 50px;
  max-height: 60px;
  min-width: 25px;
  min-height: 25px;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-size: 84px 54px; */
  /* background-clip: padding-box; */
  background-position: center;
}

.bulb-east {

  background: url("../../public/image/machine/bulb-east.png") no-repeat;
  max-width: 50px;
  max-height: 60px;
  min-width: 25px;
  min-height: 25px;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-size: 84px 54px; */
  /* background-clip: padding-box; */
  background-position: center;
}

.bulb-west {

  background: url("../../public/image/machine/bulb-west.png") no-repeat;
  max-width: 50px;
  max-height: 60px;
  min-width: 25px;
  min-height: 25px;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-size: 84px 54px; */
  /* background-clip: padding-box; */
  background-position: center;
}

.bulb-south {

  background: url("../../public/image/machine/bulb-south.png") no-repeat;
  max-width: 50px;
  max-height: 60px;
  min-width: 25px;
  min-height: 25px;
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-size: 84px 54px; */
  /* background-clip: padding-box; */
  background-position: center;
}

.bulbs-section{
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-height: 60px;
    height: 100%;
    min-height: 25px;
    min-width: 25px;
    width: 100%;
    margin-top: 7px;

    padding-right: 28px;
}


.bulb-north-try {
  animation: bulb-change 3s infinite;
}

@keyframes bulb-change {
  0% { background: url("../../public/image/machine/bulb-north.png") no-repeat; opacity: 1; background-size: cover;background-position: center}
  50% { background: url("../../public/image/machine/bulb-off.png") no-repeat; opacity: 1; background-size: cover;background-position: center}
  100% { background: url("../../public/image/machine/bulb-north.png") no-repeat; opacity: 1; background-size: cover;background-position: center}
}


.mission>div {
  border: 2px #2c2c26 solid;
  border-radius: 0.3rem;
  background-color: #2b2d36;
}

.mission-div{
  border: 2px #2c2c26 solid;
  border-radius: 0.3rem;
  background-color: #2b2d36;
}
.mission>.selected{
  border: 2px green solid;
}