

.delay-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff
}

.delay-items .num {
  font-family: Chewy, sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 4px;
  min-width: 32px;
  font-size: 22px;
  font-weight: 500;
  height: 32px;
  background: rgba(0, 0, 0, .5);
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.delay-items .delay-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff
}

.delay-items .sep {
  margin: 0 3px;
  font-size: 14px
}

.dcactives .delay-items {
  position: absolute
}

.item-bottom {
  margin: 0 16px
}

.item-bottom .value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.item-bottom .value .value-text {
  font-size: 22px;
  font-weight: 700
}

.item-bottom .value .value-token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px
}

.item-bottom .value .value-token img {
  width: 24px;
  height: 24px;
  margin-right: 4px
}

.dcactives {
  position: relative;
  overflow: hidden
}

.multi-box {
  z-index: 0;
  position: absolute;
  top: 2.8rem;
  left: 1.8rem
}

.multi-box button {
  display: grid;
  place-items: center;
  width: 52px;
  height: 58px;
  font-size: 28px;
  background: #252a2ee6;
  color: #cdc7bc;
  transform: translate(-50%, -50%);
  cursor: pointer;
  box-shadow: 0 0 0 -.25rem #252a2e
}

*,
::after,
::before {
  border-width: 0
}

.multi-box button:hover {
  background: #b2ac9d;
  color: #491c03;
  box-shadow: 0 0 1rem -.25rem #7a5e46
}

.me-3 img {
  width: 38px;
  height: 38px
}

.header {
  position: fixed;
  top: 0;
  width: 100%
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .brand-title {
  display: none
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .nav-header .logo-abbr {
  display: block
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu>ul.collapse:not(.in) {
  height: 252px !important
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(70vw + 3.75rem)
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(70vw + 3rem)
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 200px;
  width: 70vw
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul ul a {
  width: 101%
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(55vw + 3rem)
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 200px;
  width: 55vw
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul ul a {
  width: 101%
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>a {
  width: calc(45vw + 3)
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul ul a {
  width: 101%
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(30vw + 3)
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 30vw
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul ul a {
  width: 101%
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li>a {
  font-size: 16px;
  padding: 15px 30px 15px 30px;
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s
}

@media only screen and (max-width:1400px) {
  [data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li>a {
    font-size: 16px
  }
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li>a:before {
  position: absolute;
  height: 100%;
  background: #ffc100;
  width: 0;
  content: "";
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
  top: 0;
  right: 0;
  border-radius: 5px 0 0 5px
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li.mm-active,
[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li:hover {
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li.mm-active>a,
[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li:hover>a {
  background: -moz-linear-gradient(left, rgba(153, 104, 30, 0) 0, rgba(180, 108, 19, .4) 100%);
  background: -webkit-linear-gradient(left, rgba(153, 104, 30, 0) 0, rgb(180, 108, 19, .4) 100%);
  background: linear-gradient(to right, rgba(153, 104, 30, 0) 0, rgb(180, 108, 19, .4) 100%);
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
  color: #fff
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li.mm-active>a i,
[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li:hover>a i {
  color: #af7f46;
  font-weight: 400
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li.mm-active>a:before,
[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li:hover>a:before {
  width: 8px
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li .has-arrow:after {
  right: 1.125rem
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .nav-header {
  width: 5rem;
  z-index: 999
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #542b00 !important
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .copyright,
[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .plus-box {
  display: none
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .header {
  padding-left: 7.5rem;
  width: 100%
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav {
  width: 5rem;
  overflow: visible
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .nav-text {
  display: none
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .deznav-scroll,
[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .slimScrollDiv {
  overflow: visible !important
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu {
  padding-right: 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li {
  position: relative
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li a {
  padding: .8125rem .9375rem
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li a svg {
  max-width: 24px;
  max-height: 24px;
  margin-right: 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li a:before {
  content: none
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li>ul {
  position: absolute;
  left: 5rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 1px;
  height: auto !important;
  box-shadow: 0 0 40px 0 rgba(82, 63, 105, .1);
  border-radius: 0;
  margin-left: 0;
  border: 0;
  background: #383838
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li>ul:after {
  content: "";
  position: absolute;
  background: inherit;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  left: -5px;
  top: 20px
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li>ul li:hover ul {
  left: 11.8125rem;
  top: 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li>ul li:hover ul:after {
  content: none
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu li:hover>ul {
  display: block;
  height: auto;
  overflow: visible
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li {
  transition: all .4s ease-in-out;
  padding: 0 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li>a {
  text-align: center
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li>a.has-arrow:after {
  display: none
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li>a i {
  padding-right: 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mm-active>a {
  background: #5e599d;
  border-radius: 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li.mm-active>a i {
  color: #fff
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li:hover:nth-last-child(-n+1)>ul {
  bottom: 0;
  top: auto
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li:hover>a {
  border-radius: 0;
  background: #5e599d;
  color: #fff
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li:hover>a {
  background: #5e599d
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li:hover>a i {
  color: #fff;
  padding-right: 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li:hover>ul {
  height: auto !important;
  padding: 10px 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li:hover>ul a {
  padding: 6px 20px 6px 20px;
  margin-left: -.1rem
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li:hover>ul ul {
  padding: 10px 0
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu>li:hover>ul ul a {
  padding: 6px 20px 6px 20px;
  margin-left: -.1rem
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu .nav-badge,
[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .deznav .metismenu .nav-label {
  display: none
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle .content-body {
  margin-left: 5rem
}

[data-sidebar="hakkv"][data-layout=verty] .menu-toggle+.footer {
  padding-left: 7.5rem
}

.arrow_disabled{
  pointer-events: none;
}