.stats h4{
  font-family: Pangolin, sans-serif !important;
  color:white !important;
}
.page-titles {
  background: #2b2d36;
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 18px 40px;
  margin-top: -40px;
  border-radius: 0
}

@media only screen and (max-width:1400px) {
  .page-titles {
    margin-top: -30px
  }
}

@media only screen and (max-width:767px) {
  .page-titles {
    margin-bottom: 15px;
    margin-top: -20px
  }
}

@media only screen and (max-width:575px) {
  .page-titles {
    margin-top: -15px
  }
}

.pagination .page-item.active .page-link {
  background: #380d00 !important;
  border-color: #380d00 !important;
  color: #fff
}

.pagination .page-item .page-link {
  background: rgb(84 77 68) !important;
  border: 2px solid #36312b !important
}

.pagination .page-item.active .page-link {
  box-shadow: 0 10px 20px 0 rgba(54 14 0 / .35) !important
}

.pagination .page-item .page-link:hover {
  background: #380d00 !important;
  border-color: #380d00 !important
}

.nft-alpha-box[listbx] {
  overflow: hidden;
  position: relative;
  background-color: var(--bg-dialog-color)
}

.nft-alpha-box .inner-view[listbx] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain
}

.small[listbx] {
  border-radius: 16px
}

@media screen and (max-width:425px) {
  .small[listbx] {
    border-radius: 12px
  }
}

.detail-box-view {
  margin-bottom: 64px
}

.detail-box-view .box-content {
  background-color: #36312b;
  border-radius: 40px;
  padding: 32px
}

@media screen and (max-width:960px) {
  .detail-box-view {
    margin-bottom: 32px
  }

  .detail-box-view .box-content {
    margin-top: 20px;
    border-radius: 32px;
    padding: 16px
  }
}

.detail-layout-page {
  padding: 2px 0
}

.detail-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 32px
}

.detail-layout .detail-aside {
  width: 360px;
  background-color: #2c1c18;
  padding: 28px;
  border-radius: 40px;
  position: sticky;
  top: 32px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.detail-layout .detail-right {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 32px
}

@media screen and (max-width:1439px) {
  .detail-layout .detail-aside {
    padding: 24px;
    width: 320px
  }

  .detail-layout .detail-right {
    margin-left: 24px
  }
}

@media screen and (max-width:960px) {
  .detail-layout-page {
    padding: 20px 15px
  }

  .detail-layout {
    margin-top: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .detail-layout .detail-aside {
    width: 100%;
    position: unset;
    border-radius: 32px;
    top: unset;
    padding: 16px
  }

  .detail-layout .detail-right {
    margin-left: unset;
    margin-top: 30px;
    width: 100%
  }
}

.num_activs[backpack] {
  margin-top: 14px;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #998c7d;
  padding: 0 12px
}

.num_activs b[backpack] {
  color: var(--text-color)
}

.notebx[backpack] {
  border-top: solid 2px #998c77;
  margin-top: 28px;
  padding-top: 20px;
  font-size: 18px;
  color: #998c7d
}

.nft-table-list[backpack] {
  margin-bottom: -20px
}

.nft-table-list .table-container .table-item[backpack] {
  min-height: 80px
}

.nft-table-list .td-item[backpack]:nth-child(3) {
  -webkit-box-flex: 2.8;
  -ms-flex: 2.8;
  flex: 2.8
}

.nft-table-list .td-item .icon[backpack] {
  border-radius: 30px
}

@media (max-width:1200px) {
  .nft-table-list .table-container .td-item[backpack]:first-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-size: 12px
  }

  .nft-table-list .table-container .td-item:first-child .icon[backpack] {
    margin-bottom: 6px
  }
}

@media (max-width:960px) {
  .right-view[backpack] .box-content {
    padding: 20px 0
  }

  .nft-table-list[backpack] {
    overflow: auto;
    padding: 0 20px
  }

  .nft-table-list .table-inner[backpack] {
    width: 100%;
    min-width: 440px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block
  }
}

a {
  color: #06c;
  text-decoration: none
}

a:active,
a:focus,
a:hover {
  text-decoration: underline
}

section {
  display: block
}

a,
a:active,
a:focus,
a:hover {
  color: var(--text-color);
  text-decoration: none;
  outline: 0
}

@media screen and (min-width:960px) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: 0 0
  }

  ::-webkit-scrollbar-button {
    display: none
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--scrollbar-bg-color)
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: 0 0
  }
}

:root {
  --primary-color: #fad551;
  --bg-page-color: #090a19;
  --bg-box-color: #18182c;
  --bg-dialog-color: #1d2237;
  --bg-dialog-box-color: #2a304b;
  --text-color: #fff;
  --text-light-color: #a7acd0;
  --text-gray-color: #6e74a4;
  --text-green-color: #1ec196;
  --text-red-color: #f15f61;
  --border-color: #1d2237;
  --gradient-menu-bg: linear-gradient(180deg, rgba(253, 236, 137, 0.32), rgba(250, 213, 81, 0.32));
  --gradient-tag-red: linear-gradient(180deg, #ff5454, red);
  --gradient-tag-gray: linear-gradient(180deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.4));
  --aside-bg-color: hsla(0, 0%, 100%, 0.05);
  --button-alpha-10-bg-color: hsla(0, 0%, 100%, 0.1);
  --scrollbar-bg-color: hsla(0, 0%, 100%, 0.1)
}

.butying {
  border: 0 none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  padding: 0 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 30px;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.butying,
.butying:active,
.butying:focus,
.butying:hover,
.butying:visited {
  color: var(--bg-page-color)
}

.butying:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px)
}

.butying.button-nt {
  background: linear-gradient(to right, #c36200 0, #ff8e00 100%)
}

.butying.botrxr {
  height: 54px;
  font-size: 20px
}

.img-vix {
    max-width: 100%;
    height: auto;
}

@media screen and (max-width:960px) {
  .butying:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  .butying.botrxr {
    height: 48px
  }
}

.square-box {
  padding-bottom: 100%;
  height: 0
}

.left-aside-bpack>.title {
  margin-top: 22px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  word-break: break-word;
  line-height: 1.2
}

.left-aside-bpack>.buttons {
  margin-top: 24px
}

.left-aside-bpack>.buttons a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.nft-table-list {
  text-align: left;
  font-size: 17px;
  color: #fff
}

.nft-table-list .table-container .bold {
  font-weight: 600
}

.nft-table-list .table-container .table-item {
  padding: 6px 10px
}

.nft-table-list .table-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 54px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: solid 4px #48423b
}

.nft-table-list .table-item:last-child {
  border-bottom: 0 none
}

.nft-table-list .table-item .td-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 6px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  line-height: 1.15;
  word-break: break-word
}

.nft-table-list .table-item .td-item:first-child {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2
}

.nft-table-list .table-item .td-item.flex-left,
.nft-table-list .table-item .td-item:first-child {
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.nft-table-list .table-item .icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%
}

.nft-table-list .table-header {
  height: 38px;
  min-height: unset;
  color: #8f8375;
  background-color: #2c1c18;
  border-radius: 30px;
  padding: 0 10px
}

.character-contnent img {
  display: block;
  margin-left: auto;
  margin-right: auto
}

@media screen and (max-width:960px) {
  .nft-table-list .table-container .table-item {
    padding: 4px 6px
  }

  .nft-table-list .table-item .td-item {
    padding: 0 4px
  }

  .nft-table-list .table-item .td-item:first-child {
    -webkit-box-flex: 1.6;
    -ms-flex: 1.6;
    flex: 1.6
  }

  .nft-table-list .table-item .icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: 10px
  }
}

@media screen and (max-width:425px) {
  .nft-table-list {
    font-size: 12px
  }
}

.ivpg {
  margin-bottom: 0 !important;
  margin-top: 30px !important
}

.pagination .page-item.active .page-link {
  background: #380d00 !important;
  border-color: #380d00 !important;
  color: #fff
}

.pagination .page-item .page-link {
  background: rgb(84 77 68) !important;
  border: 2px solid #36312b !important
}

.pagination .page-item.active .page-link {
  box-shadow: 0 10px 20px 0 rgba(54 14 0 / .35) !important
}

.pagination .page-item .page-link:hover {
  background: #380d00 !important;
  border-color: #380d00 !important
}

.nft-alpha-box[listbx] {
  overflow: hidden;
  position: relative;
  background-color: var(--bg-dialog-color)
}

.nft-alpha-box .inner-view[listbx] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain
}

.small[listbx] {
  border-radius: 16px
}

@media screen and (max-width:425px) {
  .small[listbx] {
    border-radius: 12px
  }
}

.detail-box-view {
  margin-bottom: 64px
}

.detail-box-view .box-content {
  background-color: #36312b;
  border-radius: 40px;
  padding: 32px
}

@media screen and (max-width:960px) {
  .detail-box-view {
    margin-bottom: 32px
  }

  .detail-box-view .box-content {
    margin-top: 20px;
    border-radius: 32px;
    padding: 16px
  }
}

.detail-layout-page {
  padding: 2px 0
}

.detail-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 32px
}

.detail-layout .detail-aside {
  width: 360px;
  background-color: #2c1c18;
  padding: 28px;
  border-radius: 40px;
  position: sticky;
  top: 32px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.detail-layout .detail-right {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 32px
}

@media screen and (max-width:1439px) {
  .detail-layout .detail-aside {
    padding: 24px;
    width: 320px
  }

  .detail-layout .detail-right {
    margin-left: 24px
  }
}

@media screen and (max-width:960px) {
  .detail-layout-page {
    padding: 20px 15px
  }

  .detail-layout {
    margin-top: 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .detail-layout .detail-aside {
    width: 100%;
    position: unset;
    border-radius: 32px;
    top: unset;
    padding: 16px
  }

  .detail-layout .detail-right {
    margin-left: unset;
    margin-top: 30px;
    width: 100%
  }
}

.num_activs[backpack] {
  margin-top: 14px;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #998c7d;
  padding: 0 12px
}

.num_activs b[backpack] {
  color: var(--text-color)
}

.notebx[backpack] {
  border-top: solid 2px #998c77;
  margin-top: 28px;
  padding-top: 20px;
  font-size: 18px;
  color: #998c7d
}

.nft-table-list[backpack] {
  margin-bottom: -20px
}

.nft-table-list .table-container .table-item[backpack] {
  min-height: 80px
}

.nft-table-list .td-item[backpack]:nth-child(3) {
  -webkit-box-flex: 2.8;
  -ms-flex: 2.8;
  flex: 2.8
}

.nft-table-list .td-item .icon[backpack] {
  border-radius: 30px
}

@media (max-width:1200px) {
  .nft-table-list .table-container .td-item[backpack]:first-child {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-size: 12px
  }

  .nft-table-list .table-container .td-item:first-child .icon[backpack] {
    margin-bottom: 6px
  }
}

@media (max-width:960px) {
  .right-view[backpack] .box-content {
    padding: 20px 0
  }

  .nft-table-list[backpack] {
    overflow: auto;
    padding: 0 20px
  }

  .nft-table-list .table-inner[backpack] {
    width: 100%;
    min-width: 440px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block
  }
}

a {
  color: #06c;
  text-decoration: none
}

a:active,
a:focus,
a:hover {
  text-decoration: underline
}

section {
  display: block
}

a,
a:active,
a:focus,
a:hover {
  color: var(--text-color);
  text-decoration: none;
  outline: 0
}

@media screen and (min-width:960px) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: 0 0
  }

  ::-webkit-scrollbar-button {
    display: none
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--scrollbar-bg-color)
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: 0 0
  }
}

:root {
  --primary-color: #fad551;
  --bg-page-color: #090a19;
  --bg-box-color: #18182c;
  --bg-dialog-color: #1d2237;
  --bg-dialog-box-color: #2a304b;
  --text-color: #fff;
  --text-light-color: #a7acd0;
  --text-gray-color: #6e74a4;
  --text-green-color: #1ec196;
  --text-red-color: #f15f61;
  --border-color: #1d2237;
  --gradient-menu-bg: linear-gradient(180deg, rgba(253, 236, 137, 0.32), rgba(250, 213, 81, 0.32));
  --gradient-tag-red: linear-gradient(180deg, #ff5454, red);
  --gradient-tag-gray: linear-gradient(180deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.4));
  --aside-bg-color: hsla(0, 0%, 100%, 0.05);
  --button-alpha-10-bg-color: hsla(0, 0%, 100%, 0.1);
  --scrollbar-bg-color: hsla(0, 0%, 100%, 0.1)
}

.butying {
  border: 0 none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  padding: 0 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 30px;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.butying,
.butying:active,
.butying:focus,
.butying:hover,
.butying:visited {
  color: var(--bg-page-color)
}

.butying:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px)
}

.butying.button-nt {
  background: linear-gradient(to right, #c36200 0, #ff8e00 100%)
}

.butying.botrxr {
  height: 54px;
  font-size: 20px
}

@media screen and (max-width:960px) {
  .butying:hover {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  .butying.botrxr {
    height: 48px
  }
}

.square-box {
  padding-bottom: 100%;
  height: 0
}

.left-aside-bpack>.title {
  margin-top: 22px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  word-break: break-word;
  line-height: 1.2
}

.left-aside-bpack>.buttons {
  margin-top: 24px
}

.left-aside-bpack>.buttons a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.nft-table-list {
  text-align: left;
  font-size: 17px;
  color: #fff
}

.nft-table-list .table-container .bold {
  font-weight: 600
}

.nft-table-list .table-container .table-item {
  padding: 6px 10px
}

.nft-table-list .table-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 54px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: solid 4px #48423b
}

.nft-table-list .table-item:last-child {
  border-bottom: 0 none
}

.nft-table-list .table-item .td-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 6px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
  line-height: 1.15;
  word-break: break-word
}

.nft-table-list .table-item .td-item:first-child {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2
}

.nft-table-list .table-item .td-item.flex-left,
.nft-table-list .table-item .td-item:first-child {
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start
}

.nft-table-list .table-item .icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%
}

.nft-table-list .table-header {
  height: 38px;
  min-height: unset;
  color: #8f8375;
  background-color: #2c1c18;
  border-radius: 30px;
  padding: 0 10px
}

.character-contnent img {
  display: block;
  margin-left: auto;
  margin-right: auto
}

@media screen and (max-width:960px) {
  .nft-table-list .table-container .table-item {
    padding: 4px 6px
  }

  .nft-table-list .table-item .td-item {
    padding: 0 4px
  }

  .nft-table-list .table-item .td-item:first-child {
    -webkit-box-flex: 1.6;
    -ms-flex: 1.6;
    flex: 1.6
  }

  .nft-table-list .table-item .icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    margin-right: 10px
  }
}

@media screen and (max-width:425px) {
  .nft-table-list {
    font-size: 12px
  }
}

.ivpg {
  margin-bottom: 0 !important;
  margin-top: 30px !important
}

.available-to-steal {
  animation: color-change 3s infinite;
}

@keyframes color-change {
  0% { background-color: #252a2ee6; }
  50% { background-color: darkgreen; }
  100% { background-color: #252a2ee6; }
}

.available-to-continue {
  animation: color-change-yellow 3s infinite;
}

@keyframes color-change-yellow {
  0% { background-color: #252a2ee6; }
  50% { background-color: peru; }
  100% { background-color: #252a2ee6; }
}