.tabs_wrap ul,
.th_24hy {
  text-transform: uppercase
}

.input,
.tabs_wrap ul li,
.td_27ro,
.th_24hy {
  text-align: center
}

.tr_T2,
.tr_ne {
  color: inherit;
  vertical-align: middle;
  outline: 0
}

.page-titles {
  background: #2b2d36;
  font-size: 18px;
  padding: 18px 40px;
  margin: -40px -40px 10px;
  border-radius: 0
}

.th_24hy {
  padding: 18px;
  font-weight: 700;
  display: table-cell;
  font-size: 17px;
  font-family: Chewy, sans-serif;
  border-right: 4px solid #24292d
}

@media only screen and (max-width:1400px) {
  .page-titles {
    margin-top: -30px
  }
}

@media only screen and (max-width:767px) {
  .page-titles {
    margin-bottom: 15px;
    margin-top: -20px
  }
}

@media only screen and (max-width:575px) {
  .page-titles {
    margin-top: -15px
  }
}

.tabs_wrap {
  padding: 20px
}

.tabs_wrap ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 17px
}

.tabs_wrap ul li {
  width: 138px;
  background: #343a3e;
  border-right: 2px solid #24292d;
  padding: 13px 15px;
  cursor: pointer;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s
}

.tabs_wrap ul li:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px
}

.tabs_wrap ul li:last-child {
  border-right: 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px
}

.tabs_wrap ul li.active,
.tabs_wrap ul li:hover {
  background: #b86700;
  color: #fff
}

.wrapper_1Q {
  display: flex;
  flex: 1 1;
  border-radius: 50%;
  overflow: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content
}

.image_2RN,
.root_Zf9,
.table_5rg {
  width: 100%
}

.image_2RN {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s
}

.opit_1H {
  opacity: 1
}

.head_2dt {
  display: table-header-group
}

.tr_ne {
  display: table-row;
  background: #2b2d36
}

.body_3W {
  display: table-row-group
}

.tr_T2 {
  display: table-row;
  background: #343a3e
}

.td_27ro {
  padding: 18px;
  display: table-cell;
  line-height: 15px;
  font-size: 17px;
  border-bottom: 4px solid #24292d;
  vertical-align: inherit;
  font-weight: 700
}

.button,
.input {
  font-weight: 600
}

@media(max-width:600px) {
  .th_24hy {
    padding: 10px
  }

  .td_27ro {
    padding: 10px 0;
    font-size: .75rem
  }

  .root_Zf9 {
    padding: 0 8px
  }
}

.table_5rg {
  display: table;
  border-spacing: 0;
  color: #fff;
  border-collapse: collapse
}

.circle_5w {
  display: flex;
  border-radius: 50%
}

.userimg_ {
  width: 40px;
  height: 40px
}

.tavb_3P {
  margin: 0 15px
}

.tbwrapper_2w {
  padding-top: 10px
}

.table_wp1D {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

.user_name_fx {
  justify-content: flex-start
}

.wallet__dcyc {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

@media(max-width:350px) {
  .wallet__dcyc {
    max-width: 60px
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.col-lg-12,
.col-md-12,
.col-xl-6 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px
}

@media (min-width:768px) {
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media (min-width:992px) {
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
  }
}

@media (min-width:1200px) {
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
  }
}

.button {
  display: inline-flex;
  padding: 8px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  background: 0 0;
  border: none;
  border-radius: 10px;
  color: #888;
  text-decoration: none;
  transition: background-color 250ms cubic-bezier(.73, .09, .21, .96), border-color 250ms cubic-bezier(.73, .09, .21, .96), color 250ms cubic-bezier(.73, .09, .21, .96), opacity 250ms cubic-bezier(.73, .09, .21, .96)
}

.icon,
.pages {
  display: flex
}

.button:focus,
.input:focus {
  outline: 0
}

.button:active,
.input {
  box-shadow: none
}

.button:disabled {
  background: 0 0;
  opacity: .5;
  cursor: not-allowed
}

.button:not(:disabled):hover {
  background: rgba(46, 46, 46, .08);
  color: #555
}

.icon {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  fill: currentColor
}

.icon svg {
  display: block;
  width: 100%;
  height: 100%
}

.input {
  width: 40px;
  max-width: 100%;
  padding: 10px;
  background-color: #f4f4f4;
  border: 0;
  border-radius: 10px;
  color: #2e2e2e
}

.input::-moz-placeholder {
  color: #888;
  font-weight: 400;
  opacity: 1
}

.input:-ms-input-placeholder {
  color: #888;
  font-weight: 400;
  opacity: 1
}

.input:disabled {
  opacity: .5;
  cursor: not-allowed
}

.input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield
}

.input[type=number]::-webkit-inner-spin-button,
.input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none
}

.pages {
  justify-content: center;
  align-items: center;
  margin-bottom: 2px;
  margin-top: 18px
}

.text {
  display: block;
  margin: 0 10px
}

@media only screen and (max-width:375px) {
  .pageText {
    display: none
  }
}

.banner-ld[event] {
  margin-top: 4px
}

.banner-ld .ld-item[event] {
  display: block;
  color: #fff;
  position: relative;
  margin-bottom: 20px
}

.banner-ld .ld-item .item-inner[event] {
  background: -webkit-gradient(linear, left top, right top, from(#9f560a), to(#e28c00));
  background: linear-gradient(90deg, #9f560a, #e28c00);
  border-radius: 32px;
  padding: 48px 68px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden
}

.banner-ld .ld-item .item-inner>[event] {
  position: relative
}

.banner-ld .ld-item .banner-bg[event] {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-size: cover;
  background-position: 0;
  background-repeat: no-repeat
}

.banner-ld .ld-item .stage-title[event] {
  height: 30px;
  background: rgba(0, 0, 0, .12);
  border-radius: 10px;
  font-size: 18px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 12px
}

.banner-ld .ld-item .title[event] {
  font-size: 32px;
  font-weight: 700;
  margin-top: 12px;
  line-height: 1.25;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.banner-ld .ld-item .delay-tips[event] {
  margin-top: 20px
}

.banner-ld .ld-item .delay-tips .delay-title[event] {
  font-size: 15px;
  color: hsla(0, 0%, 100%, .8)
}

.banner-ld .ld-item .delay-tips .delay-items[event] {
  margin-top: 8px
}

.banner-ld .ld-item .delay-tips .delay-items .delay-item[event],
.banner-ld .ld-item .delay-tips .delay-items[event] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.banner-ld .ld-item .delay-tips .delay-items .sep[event] {
  margin: 0 8px;
  font-size: 18px;
  font-weight: 700
}

.banner-ld .ld-item .delay-tips .delay-items .num[event] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 6px;
  min-width: 40px;
  font-size: 18px;
  font-weight: 700;
  height: 40px;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

@media screen and (max-width:960px) {
  .banner-ld[event] {
    margin-top: 4px
  }

  .banner-ld .ld-item[event] {
    margin-bottom: 10px
  }

  .banner-ld .ld-item .item-inner[event] {
    padding: 24px 30px;
    border-radius: 24px
  }

  .banner-ld .ld-item .stage-title[event] {
    font-size: 14px;
    height: 28px
  }

  .banner-ld .ld-item .title[event] {
    font-size: 24px;
    margin-top: 12px
  }

  .banner-ld .ld-item .delay-tips[event] {
    margin-top: 18px
  }

  .banner-ld .ld-item .delay-tips .delay-title[event] {
    font-size: 12px
  }

  .banner-ld .ld-item .delay-tips .delay-items[event] {
    margin-top: 10px
  }

  .banner-ld .ld-item .delay-tips .delay-items .sep[event] {
    margin: 0 6px;
    font-size: 14px
  }

  .banner-ld .ld-item .delay-tips .delay-items .num[event] {
    font-size: 16px;
    padding: 0 4px;
    min-width: 32px;
    height: 32px;
    border-radius: 8px
  }
}

.hid {
  visibility: hidden
}

@media screen and (max-width:1200px) {
  .ddn {
    visibility: hidden
  }
}

.server_op {
  display: flex;
  justify-content: center;
  margin-top: 14px
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: 17px;
  width: 10em;
  height: 2.8em;
  padding: 0 2em 0 0.8em;
  background: url(../../../public/image/login/down_.svg) no-repeat right .8em center/1.4em, linear-gradient(to left, rgba(255, 255, 255, .3) 3em, rgba(255, 255, 255, .2) 3em);
  color: #fff;
  border-radius: .25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, .2);
  cursor: pointer
}

select option {
  color: inherit;
  background-color: #343a3e
}

select:focus {
  outline: 0
}

select::-ms-expand {
  display: none
}

.cpmai-thumb {
    margin: auto;
    text-align: center

}.cpmai-thumb img {
    width: 420px;
}