
*,
::after,
::before {
  box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

.login {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .16), rgba(25, 30, 35, .8)), url(/public/image/login/dcyc-eggland.jpg) !important;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover
}

h4 {
  font-size: 26px;
  font-family: Chewy, sans-serif;
  color: #ffe6dc
}

a {
  color: #13b497;
  text-decoration: underline
}

a:hover {
  color: #0f9079
}

img {
  vertical-align: middle
}

button {
  border-radius: 0
}

button:focus:not(:focus-visible) {
  outline: 0
}

button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button {
  text-transform: none
}

.mb-3 img {
  height: 210px
}

.button-lg {
  appearance: button;
  background: linear-gradient(44deg, rgba(195,98,0,1) 43%, rgba(0,114,195,1) 56%);
  
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: .8px;
  padding: 12px 24px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.button-lg:after {
  background-clip: padding-box;
  background: linear-gradient(125deg, rgba(255,142,0,1) 43%, rgba(24,153,214,1) 56%);
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 0px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1
}

.button-lg:focus,
.button-lg:main {
  user-select: auto
}

.button-lg:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1)
}

.button-lg:disabled {
  cursor: auto
}

.bt-anchor {
  background-color: #1899d6;
  margin-top: 10px
}

.bt-anchor:after {
  background-color: #0072c3
}

.hakki {
  margin-top: 4px
}

.gg_d69:hover {
  transition: .5s;
  margin-left: -16px;
  transform: translateX(10px) rotate(360deg)
}

.social_net[dcyour] {
  display: flex;
  justify-content: center;
  margin-top: 8px
}

.social_net[dcyour] a {
  text-decoration: none;
  width: 45px;
  height: 45px;
  line-height: 45px;
  background-color: #4972a7;
  text-align: center;
  border-radius: 16px
}

.social_net[dcyour] a img {
  filter: brightness(0) invert(1)
}

.chain-end-icon img {
  display: flex;
  justify-content: center;
  height: 30px
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width:768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width:992px) {
  .container {
    max-width: 960px
  }
}

@media (min-width:1200px) {
  .container {
    max-width: 1140px
  }
}

@media (min-width:1440) {
  .container {
    max-width: 1320px
  }
}

@media (min-width:768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%
  }
}

@media (min-width:1200px) {
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%
  }
}

.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important
}

.yckh69 {
  height: 100% !important
}

.sarmx {
  height: 100vh !important
}

.justify-content-center {
  justify-content: center !important
}

.align-items-center {
  align-items: center !important
}

.mb-3 {
  margin-bottom: 1rem !important
}

.mb-4 {
  margin-bottom: 1.5rem !important
}

.footer_lg p {
  font-size: 18px
}

.important-link {
  color: #81c5b6 !important
}

* {
  outline: 0;
  padding: 0
}

::after {
  margin: 0;
  padding: 0
}

::before {
  margin: 0;
  padding: 0
}

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 1rem
}

@media only screen and (max-width:1400px) {
  body {
    font-size: .875rem
  }
}

a {
  text-decoration: none;
  color: #2f363e
}

a:focus,
a:hover {
  text-decoration: none
}

button {
  cursor: pointer
}

button:focus {
  outline: 0;
  box-shadow: none
}

.access-content {
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, .15)
}

.auth-login {
  padding: 50px 50px
}

@media only screen and (max-width:575px) {
  .auth-login {
    padding: 30px
  }
}

.server_op {
  display: flex;
  justify-content: center;
  margin-bottom: 14px
}

.rpc {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  font-size: 17px;
  width: 20em;
  height: 3em;
  padding: 0 4em 0 1.5em;
  background: url(/public/image/login/down_.svg) no-repeat right .8em center/1.4em, linear-gradient(to left, rgba(255, 255, 255, .3) 3em, rgba(255, 255, 255, .2) 3em);
  color: #fff;
  border-radius: .25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, .2);
  cursor: pointer
}

select option {
  color: inherit;
  background-color: #320a28
}

select:focus {
  outline: 0
}

select::-ms-expand {
  display: none
}