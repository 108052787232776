
  
  .cpmai-content,
  .message i:after {
    text-align: center
  }
  
  .maint-body {
    padding-top: 200px;
    background-image: url(../../../public/image/maintce/ui-account.png);
    padding-bottom:100px
  }
  
  .footer {
    padding-left: 0 !important;
  }
  
  .cpmai-thumb {
    margin: auto
  }
  
  .cpmai-thumb img {
    width: 300px
  }
  
  .cpmai-content h2 {
    color: #d35151;
    font-size: 26px;
    margin-top: 8px;
    margin-bottom: 0
  }
  
  .cpmai-content h4 {
    font-weight: 400;
    font-size: 22px;
    color: inherit;
    margin-top: 4px
  }
  
  .message h2,
  .message i:after {
    font-size: 1.25em;
    display: block
  }
  
  .message {
    background: #d7d7d7;
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, .31);
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    max-width: 40em;
    margin: 0.6em auto
  }
  
  .message i:after {
    font-family: "fontawesome";
    font-weight: 900;
    content: "";
    font-style: normal;
    color: #eee;
    background: #353535;
    padding: 1em .75em;
    width: 2.5em;
    height: 100%
  }
  
  .message h2 {
    margin: .75em;
    color: #383838;
    position: relative;
    width: calc(100% - 2.5em - 1.5em)
  }
  
  .message h2:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
  }
  
  .warning i:after {
    background: #ab2037;
    color: #ff9797;
    content: "\f071"
  }
  
  .warning h2 span {
    color: #ab2037
  }
  
  .warning h2:after {
    border-bottom: .1em solid #ab2037
  }