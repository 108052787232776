@import url(https://fonts.googleapis.com/css2?family=Chewy);
@import url(https://fonts.googleapis.com/css2?family=Pangolin);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);

@font-face {
  font-family: ChailceNoggin;
  src: url("/public/ChailceNogginRegular-2OXoW.ttf");
}

*,
::after,
::before {
  box-sizing: border-box
}

body {
  margin: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #b7b7b7;
  background-color: #24292d;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent
}

h4 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff
}

h4 {
  font-size: 1.125rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

ul {
  padding-left: 2rem
}

ul {
  margin-top: 0;
  margin-bottom: 1rem
}

a {
  color: #5e599d;
  text-decoration: none;
}

a:hover {
  color: #24292d
}

img,
svg {
  vertical-align: middle
}

button {
  border-radius: 0
}



button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button {
  text-transform: none
}

[role=button] {
  cursor: pointer
}

button {
  -webkit-appearance: button
}

::-webkit-inner-spin-button {
  height: auto
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
  padding: 0
}

::file-selector-button {
  font: inherit
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x))
}

/* check    .row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y)
}*/

@media (min-width:200px) {
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%
  }
}

@media (min-width:576px) {
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%
  }
}

@media (min-width:768px) {
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
}

@media (min-width:992px) {
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%
  }
}

@media (min-width:1200px) {
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%
  }
}

@media (min-width:1440) {
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%
  }
}



.form-control::file-selector-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  color: #b7b7b7;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-control::file-selector-button {
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3
}

.form-control-sm::file-selector-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem
}

.form-control-lg::file-selector-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #b7b7b7;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: .875rem;
  border-radius: .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: #b7b7b7
}



.btn:disabled {
  pointer-events: none;
  opacity: .65
}

.btn-primary {
  background-color: #3e3b64;
  border-color: #302f4f
}

.btn-primary:hover {
  color: #fff;
  background-color: #4b4878;
  border-color: #423f70
}







.btn-primary:disabled {
  color: #fff;
  background-color: #5e599d;
  border-color: #5e599d
}

.collapse:not(.show) {
  display: none
}

.dropdown {
  position: relative
}

.dropdown a {
  color: #b7b7b7
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: .875rem;
  color: #b7b7b7;
  text-align: left;
  list-style: none;
  background-color: #380d00;
  background-clip: padding-box;
  border-radius: .75rem
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: .125rem
}

.dropdown-menu-end {
  --bs-position: end
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem
}

.dropdown-menu.show {
  display: block
}

.dropdown-menu-start {
  --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0
}


.dropdown-item:hover {
  color: #514e5f;
  background-color: #f8f9fa
}

.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #5e599d
}

.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
  color: #5e599d;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .nav-link {
    transition: none
  }
}


.nav-link:hover {
  color: #0f9079
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0
}

.navbar-nav .dropdown-menu {
  position: static
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start
}

.navbar-expand .navbar-nav {
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #383838;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .75rem
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none
}

.page-link {
  position: relative;
  display: block;
  color: #5e599d;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .page-link {
    transition: none
  }
}

.page-link:hover {
  z-index: 2;
  color: #0f9079;
  background-color: #e9ecef;
  border-color: #dee2e6
}



.page-item:not(:first-child) .page-link {
  margin-left: -1px
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #5e599d;
  border-color: #5e599d
}

.page-link {
  padding: .375rem .75rem
}

.page-item:first-child .page-link {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem
}

.page-item:last-child .page-link {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem
}



.d-block {
  display: block !important
}

.justify-content-between {
  justify-content: space-between !important
}

.align-items-center {
  align-items: center !important
}

.align-self-center {
  align-self: center !important
}

.m-0 {
  margin: 0 !important
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important
}

.mt-2 {
  margin-top: .5rem !important
}

.mt-3 {
  margin-top: 1rem !important
}

.me-3 {
  margin-right: 1rem !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.mb-5 {
  margin-bottom: 3rem !important
}

.ms-1 {
  margin-left: .25rem !important
}

.ms-2 {
  margin-left: .5rem !important
}

.ms-3 {
  margin-left: 1rem !important
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.text-center {
  text-align: center !important
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important
}

@media (min-width:992px) {
  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }
}

@media (min-width:1200px) {
  .mt-xl-0 {
    margin-top: 0 !important
  }
}

* {
  outline: 0;
  padding: 0
}

::after {
  margin: 0;
  padding: 0
}

::before {
  margin: 0;
  padding: 0
}

::selection {
  color: #fff;
  background: #5e599d
}

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 1rem
}

@media only screen and (max-width:1400px) {
  body {
    font-size: .875rem
  }
}

p {
  line-height: 1.8
}

ul {
  padding: 0;
  margin: 0
}

li {
  list-style: none
}



a:focus,
a:hover {
  text-decoration: none
}

.content-body .container-fluid {
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px
}

@media only screen and (max-width:1400px) {
  .content-body .container-fluid {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 30px
  }
}

@media only screen and (max-width:767px) {
  .content-body .container-fluid {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px
  }
}

@media only screen and (max-width:575px) {
  .content-body .container-fluid {
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px
  }
}



.text-add {
  color: #fff !important
}

.fs-12 {
  font-size: 16px !important;
  line-height: 1.5
}

.fs-14 {
  font-size: 20px !important;
  line-height: 1.5
}

.fs-22 {
  font-size: 22px !important;
  line-height: 1.5
}

.font-w600 {
  font-weight: 600
}

@media only screen and (min-width:1200px) and (max-width:1600px) {
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%
  }
}

.footer {
  padding-left: 17.1875rem;
  background-color: transparent
}

.footer .copyright {
  padding: .9375rem;
  font-size: 20px
}

.footer .copyright p {
  text-align: center;
  margin: 0
}

.footer .copyright a {
  color: #5e599d
}

.nav-header {
  height: 5.5rem;
  width: 18rem;
  display: inline-block;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #383838;
  transition: all .2s ease;
  z-index: 5
}

.dc_min,
.nav-header .logo-abbr {
  max-width: 140px
}

.dc_min {
  display: none
}

@media only screen and (max-width:575px) {
  .nav-header .logo-abbr {
    max-width: 35px
  }
}

@media only screen and (max-width:1400px) {
  .nav-header {
    width: 16rem
  }
}

.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  text-decoration: none;
  padding-left: 50px;
  padding-right: 50px
}

@media only screen and (max-width:1023px) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center
  }
}

@media only screen and (max-width:1023px) {
  .nav-header {
    width: 5rem;
    height: 5rem
  }
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -5.0625rem;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 2px .5rem 0;
  border-radius: 2px
}

@media only screen and (max-width:767px) {
  .nav-control {
    right: -2.5rem
  }
}

.hamburger {
  display: inline-block;
  left: 0;
  position: relative;
  top: 3px;
  -webkit-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
  width: 26px;
  z-index: 999
}

.hamburger .line {
  background: #702c1d;
  display: block;
  height: 6px;
  border-radius: 3px;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: auto;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out
}

.hamburger .line:nth-child(1) {
  width: 20px
}

.hamburger .line:nth-child(2) {
  width: 26px
}

.hamburger .line:nth-child(3) {
  width: 22px
}

.hamburger:hover {
  cursor: pointer
}

.hamburger:hover .line {
  width: 26px
}

@media (min-width:767px) {
  .menu-toggle .logo-abbr {
    display: none
  }
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 12px;
  height: 4px
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  width: 20px;
  height: 4px
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(4px) rotate(45deg);
  transform: translateY(4px) rotate(45deg)
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-4px) rotate(-45deg);
  transform: translateY(-4px) rotate(-45deg)
}

.header {
  height: 5.5rem;
  z-index: 1;
  position: relative;
  padding: 0;
  background: linear-gradient(320deg, #9f5000 0, orange 100%);
  z-index: 3;
  padding-left: 21.563rem;
  transition: all .2s ease
}

@media only screen and (max-width:1400px) {
  .header {
    padding-left: 18rem
  }
}

.header .header-content {
  height: 100%;
  padding-left: 3.3125rem;
  padding-right: 1.875rem;
  align-items: center;
  display: flex
}

@media only screen and (max-width:1400px) {
  .header .header-content {
    padding-left: 6.3125rem
  }
}

@media only screen and (max-width:767px) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: .938rem
  }
}

.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%
}

.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%
}

@media only screen and (max-width:1199px) {
  .header {
    height: 5rem
  }
}

[data-sidebar="hakkv"] .header {
  width: 100%
}

@media only screen and (max-width:1400px) {
  [data-sidebar="hakkv"] .header {
    width: 100%;
    padding-left: 16rem
  }
}

@media only screen and (max-width:1023px) {
  [data-sidebar="hakkv"] .header {
    width: 100%;
    padding-left: 5rem
  }
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center
}

.header-left .dashboard_bar {
  font-size: 28px;
  font-weight: 600;
  color: #542b00
}

.btn-head {
  color: #fff;
  background-color: #5e1f00;
  border-color: #6e2500
}

@media only screen and (max-width:1199px) {
  .header-left .dashboard_bar {
    font-size: 24px
  }
}

@media only screen and (max-width:767px) {
  .header-left .dashboard_bar {
    font-size: 20px
  }
}

@media only screen and (max-width:575px) {
  .header-left .dashboard_bar {
    display: none
  }
}

.header-right {
  height: 100%
}

.header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center
}

.header-right .nav-item .nav-link {
  color: #464a53;
  font-size: 18px
}

.header-right>li:not(:first-child) {
  padding-left: 1.5rem
}

@media only screen and (max-width:767px) {
  .header-right>li:not(:first-child) {
    padding-left: .5rem
  }
}

.header-right .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 37px rgba(8, 21, 66, .05)
}

.header-right .header-profile>a.nav-link {
  padding: 0;
  border: 2px solid rgba(255, 255, 255, .05);
  background: #380d00;
  border-radius: .75rem;
  display: flex;
  white-space: nowrap;
  align-items: center
}

.header-right .header-profile>a.nav-link i {
  font-weight: 700
}

.header-right .header-profile>a.nav-link .header-info {
  padding-right: 20px;
  text-align: right;
  padding-left: 20px
}

@media only screen and (max-width:1400px) {
  .header-right .header-profile>a.nav-link .header-info {
    padding-right: 10px;
    padding-left: 10px
  }
}

@media only screen and (max-width:767px) {
  .header-right .header-profile>a.nav-link .header-info {
    display: none
  }
}

.header-right .header-profile>a.nav-link .header-info span {
  font-size: 18px;
  color: #fff;
  display: block;
  font-weight: 300
}

@media only screen and (max-width:1400px) {
  .header-right .header-profile>a.nav-link .header-info span {
    font-size: 17px
  }
}

.header-right .header-profile .dropdown-menu {
  padding: 15px 0;
  min-width: 12.5rem
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.header-right .header-profile img {
  width: 56px;
  height: 56px;
  border-radius: .75rem
}

@media only screen and (max-width:1400px) {
  .header-right .header-profile img {
    width: 40px;
    height: 40px
  }
}

.header-right .header-profile .dropdown-item {
  padding: 8px 24px
}

.content-body {
  margin-left: 18rem;
  z-index: 0;
  transition: all .2s ease
}

@media only screen and (max-width:766px) {
  .content-body {
    margin-left: 0
  }
}

@media only screen and (max-width:1199px) {
  .content-body {
    padding-top: 5rem
  }
}

.content-body {
  padding-top: 5.5rem
}

@media only screen and (max-width:1400px) {
  .content-body {
    margin-left: 16rem
  }
}

@media only screen and (max-width:766px) {
  .content-body {
    margin-left: 0
  }
}

.deznav {
  width: 18rem;
  padding-bottom: 0;
  height: calc(100% - 5.5rem);
  position: absolute;
  top: 5.5rem;
  padding-top: 0;
  z-index: 5;
  background-color: #383838;
  transition: all .2s ease
}

@media only screen and (max-width:1199px) {
  .deznav {
    background-color: #383838;
    top: 5rem;
    height: calc(100% - 80px)
  }
}

@media only screen and (max-width:1400px) {
  .deznav {
    width: 16rem
  }
}

.deznav {
  margin-top: 0
}

.nav-header {
  position: fixed
}

.deznav .deznav-scroll {
  position: relative;
  height: 100%
}

.deznav .deznav-scroll {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.deznav ul {
  padding: 0;
  margin: 0;
  list-style: none
}

.deznav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-right: 0
}

.deznav .metismenu>li {
  display: flex;
  flex-direction: column
}

.deznav .metismenu>li a>i {
  font-size: 26px;
  color: #fff;
  font-family: fontawesome;
  display: inline-block;
  vertical-align: middle;
  padding: 0 .875rem 0 0;
  position: relative;
  top: 0;
  line-height: 1
}

.deznav .metismenu .nav-text {
  font-size: 21px;
  font-family: ChailceNoggin, sans-serif
}

@media only screen and (max-width:767px) {
  .deznav .metismenu>li a>i {
    font-size: 1.25rem;
    padding: 0 .75rem 0 0
  }
}

.deznav .metismenu>li>a {
  font-weight: 400;
  display: inline-block;
  font-size: 15px
}

.deznav .metismenu>li:hover>a {
  color: #5e599d
}

.deznav .metismenu li {
  position: relative
}

.deznav .metismenu a {
  position: relative;
  display: block;
  padding: .625rem 1.875rem;
  outline-width: 0;
  color: #b7b7b7;
  text-decoration: none
}

@media only screen and (max-width:767px) {
  .deznav .metismenu a {
    padding: .625rem 1.25rem
  }
}

@media only screen and (max-width:1023px) {
  .nav-header {
    width: 5rem
  }
}

@media (max-width:767px) {
  .footer {
    padding-left: 0
  }

  .deznav {
    left: -100%;
  }
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li {
  padding: 0
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li>a {
  font-size: 16px;
  padding: 15px 30px 15px 30px;
  border-bottom: solid 8px #252a2d;
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s
}

@media only screen and (max-width:1400px) {
  [data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li>a {
    font-size: 16px
  }
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li:hover {
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li:hover>a {
  background: -moz-linear-gradient(left, rgba(30, 87, 153, 0) 0, rgba(19, 180, 151, .4) 100%);
  background: -webkit-linear-gradient(left, rgba(30, 87, 153, 0) 0, rgba(19, 180, 151, .4) 100%);
  background: linear-gradient(to right, rgba(30, 87, 153, 0) 0, rgba(19, 180, 151, .4) 100%);
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s;
  color: #5e599d
}

[data-sidebar="hakkv"][data-layout=verty] .deznav .metismenu>li:hover>a:before {
  width: 8px
}

button {
  cursor: pointer
}

button:focus {
  outline: 0;
  box-shadow: none
}

.btn {
  padding: 10px 20px;
  border-radius: .75rem;
  font-weight: 500;
  font-size: 20px
}

.btn:active,
.btn:focus,
.btn:hover {
  outline: 0 !important
}

@media only screen and (max-width:1400px) {
  .btn {
    font-size: 17px
  }
}

.btn-rounded {
  border-radius: 30px !important
}

.card {
  margin-bottom: 1.875rem;
  background-color: #383838;
  transition: all .5s ease-in-out;
  position: relative;
  border: 0 solid transparent;
  border-radius: .75rem;
  box-shadow: 0 12px 33px 0 rgba(62, 73, 84, .08);
  height: calc(100% - 30px)
}

@media only screen and (max-width:575px) {
  .card {
    margin-bottom: .938rem;
    height: calc(100% - .938rem)
  }
}

.card-body {
  padding: 1.875rem
}

@media only screen and (max-width:575px) {
  .card-body {
    padding: 1rem
  }
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  box-shadow: 0 10px 40px 0 rgb(32, 28, 69, .1);
  margin-top: 0
}

.dropdown-menu .dropdown-item {
  font-size: 16px;
  color: #b7b7b7;
  padding: .5rem 1.75rem
}

@media only screen and (max-width:1400px) {
  .dropdown-menu .dropdown-item {
    padding: .375rem 1rem;
    font-size: 14px
  }
}

.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover {
  color: #b7b7b7;
  background: #4e1c0d
}

.media img {
  border-radius: 3px
}

.pagination .page-item.active .page-link {
  background: #5e599d;
  border-color: #5e599d;
  color: #fff
}

.pagination {
  margin-bottom: 20px;
  justify-content: center
}

.pagination .page-item.page-indicator .page-link {
  padding: 6.2px 12px
}

.pagination .page-item.page-indicator:hover .page-link {
  color: #3e4954
}

.pagination .page-item .page-link {
  text-align: center;
  background: rgb(47 54 62);
  border: 2px solid #24292d
}

.pagination .page-item .page-link:hover i {
  color: #fff
}

a i {
  color: #fff
}

.deznav {
  position: fixed
}

.menu-toggle .deznav {
  position: fixed
}

.menu-toggle .deznav {
  left: 0
}

.deznav.fixed {
  position: fixed;
  left: 0;
  top: 0
}

.pagination .page-item .page-link:hover {
  background: #5e599d;
  color: #fff;
  border-color: #5e599d
}

.pagination .page-item.active .page-link {
  background-color: #5e599d;
  border-color: #5e599d;
  color: #fff;
  box-shadow: 0 10px 20px 0 rgba(103, 19, 180, .2)
}

.pagination .page-item .page-link {
  color: #fff;
  -webkit-transition: all .5s;
  -ms-transition: all .5s;
  transition: all .5s
}

.pagination .page-item:last-child .page-link {
  margin-right: 0
}

.media {
  display: flex;
  align-items: flex-start
}

.media-body {
  flex: 1
}

.btn-primary {
  color: #fff
}

.stake_mb .picture_nft {
  overflow: hidden
}

.stake_mb .picture_nft img {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto
}

.content_card h4 {
  font-size: 22px;
  color: #b7b7b7;
  font-family: Chewy, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px
}

.buttons[works] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 6px
}

.buttons[works] div {
  margin-top: 10px
}

.common-button.outline {
  border: 2px solid #fad551;
  color: #fad551
}

.common-button.button-38 {
  height: 48px;
  font-size: 20px
}

.common-button {
  color: #000
}

.common-button {
  border: 0 none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  padding: 0 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 30px;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.common-button.button-primary {
  background: #fad551
}

.delay-items {
  top: unset;
  bottom: 15px;
  right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.fxcl {
    justify-content: center;
}