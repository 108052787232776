.page-titles {
  background: #2b2d36;
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 18px 40px;
  margin-top: -40px;
  border-radius: 0
}

@media only screen and (max-width:1400px) {
  .page-titles {
    margin-top: -30px
  }
}

@media only screen and (max-width:767px) {
  .page-titles {
    margin-bottom: 15px;
    margin-top: -20px
  }
}

@media only screen and (max-width:575px) {
  .page-titles {
    margin-top: -15px
  }
}

.common-loading .animate-inner[data-v-764adde8],
.common-loading[data-v-764adde8] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}

.common-loading .animate-inner[data-v-764adde8] {
  opacity: 0;
  -webkit-transition: opacity .2s ease;
  transition: opacity .2s ease
}

.roost-box[estiv] {
  overflow: hidden;
  position: relative;
  background-color: #362d25
}

.roost-box .loading[estiv] {
  position: absolute;
  top: 15px;
  right: 15px
}

.roost-box .alpha-view[estiv],
.roost-box .picture-view[estiv] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain
}

.roost-box .alpha-view[estiv] {
  background-size: 600%;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  background-position: 40% 50%
}

.roost-box .large[estiv] {
  -webkit-filter: blur(50px);
  filter: blur(50px)
}

.large[estiv] {
  border-radius: 40px
}

.none[estiv] {
  border-radius: 0
}

@media screen and (max-width:425px) {
  .large[estiv] {
    border-radius: 32px
  }
}

.market-layout[packsmk] {
  padding: 28px 0
}

.market-layout .section-data[packsmk] {
  margin-top: -4px;
  position: relative
}

body,
div,
li,
ul {
  margin: 0;
  padding: 0
}

img {
  border: 0
}

li,
ul {
  list-style: none
}

a {
  color: #06c;
  text-decoration: none
}

a img,
img {
  -ms-interpolation-mode: bicubic
}

a:active,
a:focus,
a:hover {
  text-decoration: underline
}

a,
a:active,
a:focus,
a:hover {
  color: var(--text-color);
  text-decoration: none;
  outline: 0
}

img {
  vertical-align: top;
  max-width: 100%
}

@media screen and (min-width:960px) {
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background: 0 0
  }

  ::-webkit-scrollbar-button {
    display: none
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--scrollbar-bg-color)
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: 0 0
  }
}

:root {
  --primary-color: #fad551;
  --bg-page-color: #090a19;
  --bg-box-color: #18182c;
  --bg-dialog-color: #1d2237;
  --bg-dialog-box-color: #2a304b;
  --text-color: #fff;
  --text-light-color: #a7acd0;
  --text-gray-color: #6e74a4;
  --text-green-color: #1ec196;
  --text-red-color: #f15f61;
  --border-color: #1d2237;
  --gradient-menu-bg: linear-gradient(180deg, rgba(253, 236, 137, 0.32), rgba(250, 213, 81, 0.32));
  --gradient-tag-red: linear-gradient(180deg, #ff5454, red);
  --gradient-tag-gray: linear-gradient(180deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.4));
  --aside-bg-color: hsla(0, 0%, 100%, 0.05);
  --button-alpha-10-bg-color: hsla(0, 0%, 100%, 0.1);
  --scrollbar-bg-color: hsla(0, 0%, 100%, 0.1)
}

.section-ux {
  -webkit-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .03), 0 4px 6px -2px rgba(0, 0, 0, .01);
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, .03), 0 4px 6px -2px rgba(0, 0, 0, .01);
  position: relative;
  border-radius: 32px;
  background: var(--bg-box-color)
}

.square-gm {
  padding-bottom: 100%;
  height: 0
}

@media screen and (max-width:960px) {
  .section-ux {
    border-radius: 24px;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0
  }
}

.grid_nft {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px
}

.grid_nft,
.grid_nft .item_market {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.grid_nft .item_market {
  min-width: 230px;
  max-width: calc(25% - 30px);
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 30px 15px 0;
  border-radius: 48px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #443f36;
  -webkit-box-shadow: 0 70px 60px -40px rgba(0, 0, 0, .5);
  box-shadow: 0 70px 60px -40px rgba(0, 0, 0, .5);
  padding: 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.grid_nft .item_market .item-top .avatar {
  position: relative;
  padding-bottom: 100%;
  height: 0;
  border-radius: 48px;
  overflow: hidden
}

.payment-price-view[yuHK] {
  margin-top: 6px;
  border-radius: 32px;
  background-color: #363429;
  padding: 12px
}

.title[packtxt] {
  margin-top: 10px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  word-break: break-word;
  line-height: 1.2
}

.payment-price-view .payment-value img[yuHK] {
  width: 24px;
  height: 24px;
  margin-right: 8px
}

.payment-price-view .payment-value span[yuHK] {
  font-size: 14px;
  color: #ddcfb8;
  margin-left: 4px
}

.payment-price-view .payment-value[yuHK] {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
  justify-content: center
}

.grid_nft .item_market .item-top .title .title-text {
  font-size: 16px;
  line-height: 1.2;
  color: var(--text-color);
  word-break: break-word;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}

.grid_nft .item_market .item-bottom {
  margin: 0 8px;
  padding: 16px 0 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: solid 1px var(--bg-dialog-color)
}

.grid_nft .item_market .item-bottom .title {
  font-size: 14px;
  color: var(--text-gray-color)
}

.grid_nft .item_market .item-bottom .title,
.grid_nft .item_market .item-bottom .value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.grid_nft .item_market .item-bottom .value {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--text-color);
  margin-top: 1px
}

.grid_nft .item_market .item-bottom .value .value-text {
  font-size: 22px;
  font-weight: 700
}

.grid_nft .item_market .item-bottom .value .value-token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px
}

.grid_nft .item_market .item-bottom .value .value-token img {
  width: 18px;
  height: 18px;
  margin-right: 4px
}

@media screen and (max-width:1440px) {
  .grid_nft .item_market {
    max-width: calc(34% - 30px)
  }
}

@media screen and (max-width:948px) {
  .grid_nft .item_market {
    max-width: calc(50% - 30px)
  }
}

@media screen and (max-width:948px) {
  .grid_nft .item_market {
    max-width: calc(88% - 30px)
  }

  .grid_nft,
  .grid_nft .item_market {
    justify-content: center
  }
}

@media screen and (max-width:520px) {
  .grid_nft {
    margin: 0 -8px
  }

  .grid_nft .item_market {
    margin: 15px 8px 0;
    max-width: calc(80% - 12px)
  }
}

@media screen and (max-width:425px) {
  .grid_nft .item_market {
    min-width: 160px;
    border-radius: 24px
  }

  .grid_nft .item_market .item-top {
    padding-bottom: 0
  }

  .grid_nft .item_market .item-top .avatar {
    border-radius: 24px
  }

  .grid_nft .item_market .item-top .title {
    height: 58px
  }

  .grid_nft .item_market .item-top .title .title-text {
    font-size: 14px
  }

  .grid_nft .item_market .item-bottom {
    margin: 0 4px;
    padding-bottom: 10px
  }

  .grid_nft .item_market .item-bottom .title {
    font-size: 12px
  }

  .grid_nft .item_market .item-bottom .value .value-text {
    font-size: 18px
  }

  .grid_nft .item_market .item-bottom .value .value-token {
    font-size: 12px
  }

  .grid_nft .item_market .item-bottom .value .value-token img {
    width: 14px;
    height: 14px;
    margin-right: 2px
  }
}

@media screen and (max-width:370px) {
  .grid_nft {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .grid_nft .item_market {
    min-width: 230px
  }
}

.butrex[botrx] {
  margin-top: 10px;
  margin-bottom: 8px
}

.butrex[botrx] div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px
}

.buy-button.butt1 {
  height: 54px;
  font-size: 16px
}

.buy-button.button-ah {
  background: linear-gradient(to right, #c36200 0, #ff8e00 100%)
}

.buy-button:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px)
}

.buy-button {
  color: #383028 !important
}

.buy-button {
  border: 0 none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  padding: 0 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 30px;
  -webkit-transition: -webkit-transform .2s ease;
  transition: -webkit-transform .2s ease;
  transition: transform .2s ease;
  transition: transform .2s ease, -webkit-transform .2s ease;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}