@media(max-width:340px){

    .withdraw-section {
        border-radius: 10%;
        border-top: gray 3px solid;
     }
    
    .swap-section {
        border-radius: 10%;
        border-bottom: gray 3px solid;
     }
    }


@media(min-width:341px) {
    .withdraw-section {
        border-radius: 10%;
        border-left: gray 3px solid;
    }
        
    .swap-section {
        border-radius: 10%;
        border-right: gray 3px solid;
    }
}

.swap-rate {
    flex-basis: content;
}

.internal-balance {
    border-top: 2px gray solid;
    border-bottom: 2px gray solid;
    border-radius: 10%;
}

.chailce-font {
    font-family: ChailceNoggin,sans-serif;
}

.font-21 {
    font-size: 21px;
}