.player_xh {
  box-sizing: border-box;
  background: #343a3e;
  color: #b7b7b7;
  border-radius: 4px
}

.player_xh strong,
.rank_wl,
.vote_bt {
  color: #fff
}

.player_xh>div:first-of-type {
  padding-top: 12px
}

.player_xh .img_dcyC2 {
  position: relative
}

.player_xh>div {
  padding: 0 12px
}

.player_xh .img_dcyC2>img {
  margin-bottom: 16px;
  border-radius: 50%;
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto
}

.bg-image {
  background-color: #1c1c1f
}

.player_xh .img_dcyC2 .hiskent_kw {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0
}

.player_xh .img_dcyC2 .hiskent_kw img {
  width: 40px
}

.rank_wl>* {
  display: inline-block;
  vertical-align: middle
}

.player_xh .player-crd {
  margin-bottom: 12px;
  text-align: center
}

.player_xh .player-crd .wax_wl {
  font-size: 18px;
  font-weight: 700;
  color: #fff
}

.player_xh .player-crd>* {
  font-size: 15px;
  display: inline-block;
  width: 100%;
  line-height: 16px
}

.player_xh .player-crd small {
  text-transform: uppercase;
  color: #858482
}

.player_xh .mine_gold {
  margin-left: 8px
}

.dwsh {
  display: flex;
  -webkit-box-align: center;
  align-items: center
}

.dwsh>div:first-of-type {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  height: 20px;
  border-radius: 6px;
  overflow: hidden;
  background: rgba(0, 0, 0, .25);
  box-shadow: inset 0 2px 2px rgb(0 0 0 / 25%), 0 2px rgb(255 255 255 / 8%)
}

.dwsh .bart {
  width: 100%
}

.dwsh>div:first-of-type .izzy {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 57%;
  background: linear-gradient(135deg, #9655d8, #be65e7, #a347dc)
}

.player_xh>div>span {
  font-size: 14px;
  width: 100%;
  line-height: 16px;
  margin-top: 4px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between
}

.player_xh em {
  font-style: normal
}

.player_xh>div:last-of-type {
  margin-top: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-width: 4px;
  border-top-style: solid;
  border-color: #24292d
}

.vote_bt {
  background: linear-gradient(180deg, #7a644f, #685340);
  border-bottom: 4px solid #81654b;
  text-shadow: 0 -2px #725b46;
  -webkit-transition: .1s;
  transition: .1s;
  width: 100%
}

.player_xh .vote_bt {
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase
}

.lead_wl {
  margin-top: 18px
}

.lead_wl .grid {
  display: grid;
  gap: 1vw;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr))
}

@media(max-width:2400px) {
  .lead_wl .grid {
    gap: 1.5vw
  }
}

@media(max-width:1600px) {
  .lead_wl .grid {
    gap: 2vw
  }
}

@media(max-width:1199.98px) {
  .lead_wl .grid {
    gap: 3vw
  }
}

@media(max-width:767.98px) {
  .lead_wl .grid {
    gap: 2vw
  }
}

@media(max-width:575.98px) {
  .lead_wl .grid {
    gap: 1.5vw
  }
}

.rank_wl {
  line-height: 28px
}

.player_xh .img_dcyC2 .hiskent_kw span {
  font-size: 22px
}

.player_xh:hover {
  background: #383838;
  box-sizing: border-box;
  transform: scale(1.045)
}

.vote_bt:active,
.vote_bt:hover {
  -webkit-transform: translate(0, 5px);
  -ms-transform: translate(0, 5px);
  transform: translate(0, 5px);
  border-bottom: 0 solid #a8805c
}